import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import LinesPage from "./Pages/LinesPage/LinesPage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import AppContextConstructor from "./Context/AppContext/AppContext";
import BusStopDetails from "./Pages/BusStopDetails/BusStopDetails";
import BusLineDetails from "./Pages/BusLineDetails/BusLineDetails";
import Navbar from "./Components/Navbar/Navbar";
import LocationContextConstructor from "./Context/LocationContext/LocationContext";
import Footer from "./Components/Footer/Footer";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";
import ActiveBusLines from "./Pages/ActiveBusLines/ActiveBusLines";
import BusStopsPage from "./Pages/BusStopsPage/BusStopsPage";
import { analytics } from "../src/firebase";
import { logEvent } from "firebase/analytics";

function App() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <LocationContextConstructor>
      <AppContextConstructor>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<ActiveBusLines />} />
            <Route path="/stops" element={<BusStopsPage />} />
            <Route path="stop/:id" element={<BusStopDetails />} />
            <Route path="/lines" element={<LinesPage />} />
            <Route path="line/:id" element={<BusLineDetails />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </div>
      </AppContextConstructor>
    </LocationContextConstructor>
  );
}

export default App;
