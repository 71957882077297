import React from "react";
import { BusLines } from "../../Types/Types";
import "../../Styles/LineCardStyle/LineCardStyle.css";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utils/UtilityFunctions";
import { COLORS } from "../../Styles/GlobalStyles";

const LineCard: React.FC<BusLines> = ({ id, line, desc, active }) => {
  const navigate = useNavigate();

  const navigateTo = (id: string) => {
    navigate(`/line/${id}`);
    scrollToTop();
  };

  return (
    <div
      className="busLineDetailsContainer"
      style={{ borderColor: COLORS.mossGreen }}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        navigateTo(id);
      }}
    >
      <img
        className="imgBusLineCard"
        src="./images/img/busLinesAndBusStopDetailsLine.png"
        alt={`Bus line ${line}`}
      />
      <div className="busLineText">
        <div className="d-flex">
          <h3 className="lineTitle">
            Линија {line} {id.includes("T") && "- Транскоп"}
            {id.includes("14") && "(7)"}
            {active && (
              <img
                src="./images/img/voZivo.png"
                alt=""
                className="blinkingImageLinePage"
              />
            )}
          </h3>
        </div>
        {desc && <p className="lineDescription">{desc}</p>}
      </div>
    </div>
  );
};

export default LineCard;
