import React from "react";
import { COLORS } from "../../Styles/GlobalStyles";

const ComingSoon: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: COLORS.cardBackgroundColorLight,
      }}
    >
      <h1>Наскоро...</h1>
    </div>
  );
};

export default ComingSoon;
