import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext/AppContext";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import "../../Styles/BusStopDetailsStyle/BusStopDetailsStyle.css";
import { COLORS, MARGINS } from "../../Styles/GlobalStyles";
import Container from "../../Components/Container/Container";
import {
  findClosestTimes,
  formatTime,
  scrollToTop,
} from "../../Utils/UtilityFunctions";
import FullTimeScheduledModal from "../../Components/FullTimeScheduledModal/FullTimeScheduledModal";

const BusStopDetails = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const {
    getBusStopDetailsById,
    busStopDetails,
    resetBusStopDetails,
    fullScheduledTimeModal,
    openScheduledTimeModal,
    busLines,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (id) {
      getBusStopDetailsById(id);
      scrollToTop();
      interval = setInterval(() => {
        getBusStopDetailsById(id);
      }, 60000);
    }

    return () => {
      resetBusStopDetails();
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [id]);

  const handleToggleCollapse = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  if (!busStopDetails) {
    return <LoadingPage />;
  }

  const navigateTo = (id: string) => {
    navigate(`/line/${id}`);
  };

  const currentTime = new Date();
  const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

  const sortedBusStops = busStopDetails.scheduled
    .map((item) => {
      const activeBus = busStopDetails.activeBuses.find(
        (bus) => bus.line === item.line
      );

      const { closestTime, nextTimes } = findClosestTimes(
        currentMinutes,
        item.scheduledTime
      );

      let activeTime: string | null = null;

      if (activeBus) {
        activeTime = formatTime(Number(activeBus.totalTime));
      } else if (closestTime) {
        activeTime = "";
      }

      return { ...item, activeTime, nextTimes };
    })
    .sort((a, b) => {
      if (a.activeTime && !b.activeTime) {
        return -1;
      }
      if (!a.activeTime && b.activeTime) {
        return 1;
      }

      const aTime = a.activeTime ? a.activeTime : a.nextTimes[0];
      const bTime = b.activeTime ? b.activeTime : b.nextTimes[0];

      return aTime.localeCompare(bTime);
    });

  return (
    <div>
      <div
        className="titleContent"
        style={{
          backgroundColor: COLORS.busStationTitleBackgroundLight,
          marginTop: "110px",
        }}
      >
        <h1>{busStopDetails.stopName}</h1>
        {busStopDetails.scheduled.length > 0 && (
          <div className="d-flex">
            {busStopDetails.scheduled.map((item, index) => (
              <p
                className="titleLineNumbers"
                key={`${item.id}-${index}`}
                style={{ backgroundColor: COLORS.mossGreen }}
              >
                {item.line}
              </p>
            ))}
          </div>
        )}
        {busStopDetails.scheduled.length === 0 && (
          <p>Нема Линии на оваа постојка.</p>
        )}
      </div>

      <Container>
        {sortedBusStops.map((item, index) => {
          const sameBusStop = busLines.find(
            (busLine) => busLine.id === item.id
          );

          return (
            <div
              className="busStopDetailsCard"
              key={`${item.id}-${index}`}
              onClick={(
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                event.preventDefault();
                navigateTo(item.id);
                scrollToTop();
              }}
            >
              <div className="busStopDetailsCardInnerContainer">
                <div className="leftSide">
                  <img
                    className="img"
                    src="/images/img/busLinesAndBusStopDetailsLine.png"
                    style={{ marginRight: MARGINS.margin10 }}
                    alt=""
                  />
                  <div>
                    <h3 className="lineNameH3 mb-1">
                      Линија: {item.line}{" "}
                      {item.id.includes("T") && "- Транскоп"}{" "}
                      {item.id.includes("14") && "(7)"}{" "}
                      {item.activeTime && (
                        <img
                          src="/images/img/voZivo.png"
                          alt="Active"
                          className="blinkingImage"
                        />
                      )}
                    </h3>
                    {sameBusStop && (
                      <p className="mb-1" style={{ color: "grey" }}>
                        {sameBusStop.desc}
                      </p>
                    )}

                    <div className="timeContainer">
                      {item.nextTimes.length > 0 && (
                        <div className="scheduledNumbers">
                          {item.activeTime && (
                            <p
                              className="activeBusContainer"
                              style={{ backgroundColor: COLORS.mossGreen }}
                            >
                              {item.activeTime}
                            </p>
                          )}

                          {!item.activeTime && (
                            <>
                              {item.nextTimes.slice(0, 4).map((time, index) => {
                                return (
                                  <p
                                    key={`${time}-${index}`}
                                    className="inactiveNumbers"
                                    style={{
                                      backgroundColor: COLORS.timeBoxColorLight,
                                    }}
                                  >
                                    {time}
                                  </p>
                                );
                              })}
                            </>
                          )}

                          {item.activeTime &&
                            item.nextTimes.slice(0, 3).map((time, index) => {
                              return (
                                <p
                                  key={`${time}-${index}`}
                                  className="inactiveNumbers"
                                  style={{
                                    backgroundColor: COLORS.timeBoxColorLight,
                                  }}
                                >
                                  {time}
                                </p>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div
                onClick={(
                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => {
                  event.stopPropagation();
                  handleToggleCollapse(index);
                }}>
                <small className="arrow">
                  {expandedIndex === index ? "↑" : "↓"}
                </small>
              </div> */}
                {item.nextTimes.length >= 4 && (
                  <div
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      handleToggleCollapse(index);
                    }}
                  >
                    <small className="arrow">
                      {expandedIndex === index ? "↑" : "↓"}
                    </small>
                  </div>
                )}
              </div>
              {/* {expandedIndex === index && (
              <div className="collapseSection">
                <div className="collapseSectionInner">
                  {item.activeTime ? (
                    <>
                      {item.nextTimes.slice(3).map((time, index) => {
                        return (
                          <p
                            key={`${time + 1}-${index}`}
                            className="inactiveNumbersCollapse"
                            style={{
                              backgroundColor: COLORS.timeBoxColorLight,
                            }}>
                            {time}
                          </p>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {item.nextTimes.slice(4).map((time, index) => {
                        return (
                          <p
                            key={`${time + 1}-${index}`}
                            className="inactiveNumbersCollapse"
                            style={{
                              backgroundColor: COLORS.timeBoxColorLight,
                            }}>
                            {time}
                          </p>
                        );
                      })}
                    </>
                  )}
                </div>

                <button
                  className="customButton"
                  style={{ color: COLORS.mossGreen }}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.stopPropagation();
                    openScheduledTimeModal();
                  }}>
                  Целосен возен ред за Линија {item.line}
                </button>
                {fullScheduledTimeModal && (
                  <FullTimeScheduledModal
                    id={item.id}
                    lineName={item.line}
                    arrayOfTimes={item.scheduledTime}
                  />
                )}
              </div>
            )} */}
              {expandedIndex === index && item.nextTimes.length >= 4 && (
                <div className="collapseSection">
                  <div className="collapseSectionInner">
                    {item.activeTime ? (
                      <>
                        {item.nextTimes.slice(3).map((time, index) => (
                          <p
                            key={`${time + 1}-${index}`}
                            className="inactiveNumbersCollapse"
                            style={{
                              backgroundColor: COLORS.timeBoxColorLight,
                            }}
                          >
                            {time}
                          </p>
                        ))}
                      </>
                    ) : (
                      <>
                        {item.nextTimes.slice(4).map((time, index) => (
                          <p
                            key={`${time + 1}-${index}`}
                            className="inactiveNumbersCollapse"
                            style={{
                              backgroundColor: COLORS.timeBoxColorLight,
                            }}
                          >
                            {time}
                          </p>
                        ))}
                      </>
                    )}
                  </div>

                  <button
                    className="customButton"
                    style={{ color: COLORS.mossGreen }}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      openScheduledTimeModal();
                    }}
                  >
                    Целосен возен ред за Линија {item.line}
                  </button>
                  {fullScheduledTimeModal && (
                    <FullTimeScheduledModal
                      id={item.id}
                      lineName={item.line}
                      arrayOfTimes={item.scheduledTime}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </Container>
    </div>
  );
};

export default BusStopDetails;
