import React, { useContext, useEffect, useState } from "react";
import Container from "../../Components/Container/Container";
import { AppContext } from "../../Context/AppContext/AppContext";
import { Link } from "react-router-dom";
import ActiveLineCard from "../../Components/ActiveLineCard/ActiveLineCard";
import "../../Styles/ActiveBusLinesPageStyles/ActiveBusLinesPageStyles.css";
import { COLORS } from "../../Styles/GlobalStyles";

const ActiveBusLines = () => {
  const { activeBusHP, fetchActiveBuses } = useContext(AppContext);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [showLoader, setShowLoader] = useState<boolean>(true); // Initially show the loader
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    const interval = setInterval(() => {
      fetchActiveBuses();
    }, 60000);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (activeBusHP && activeBusHP.length > 0) {
      setShowLoader(false);
      setDataLoaded(true);
    } else {
      setShowLoader(true);
      setDataLoaded(false);
    }
  }, [activeBusHP]);

  return (
    <div
      style={{
        marginTop: "90px",
        minHeight: `${viewportHeight - 90}px`,
      }}
    >
      <Container>
        {showLoader ? (
          <div className="d-flex justify-content-center">
            <div
              className="spinner"
              style={{ marginBottom: "16px", borderTopColor: COLORS.mossGreen }}
            />
          </div>
        ) : dataLoaded && activeBusHP.length > 0 ? (
          <>
            <div className="text-center mb-3">
              <h4 className="m-0">Активни линии во моментов</h4>
            </div>
            {activeBusHP.map((item, index) => (
              <ActiveLineCard {...item} key={`${item.id}-${index}`} />
            ))}
          </>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="text-center my-5">
                <h1>Нема активни линии во моментов.</h1>
                <p>Погледнете ги сите линии или сите постојки.</p>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <Link
              to={"/lines"}
              className="navigateToLineScreenButton marginBottomButton text-white"
              style={{
                textDecoration: "none",
                backgroundColor: COLORS.mossGreen,
              }}
            >
              Погледнете ги сите линии!
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ActiveBusLines;
